import ActionType from './type';


export const updateUserInfo = (userInfo) => ({
    type: ActionType.UPDATE_USER_INFO,
    userInfo
})

export const updateOperation = (operateState) => ({
    type: ActionType.UPDATE_USER_OPERATION,
    operateState
})

export const updateCovertType = (convertType) => ({
    type: ActionType.UPDATE_CONVERT_TYPE,
    convertType
})

export const updateLocale = (currentLocale) => ({
    type: ActionType.UPDATE_LOCALE,
    currentLocale
})

export const updateEditable = (status) => ({
    type: ActionType.UPDATE_EDITABLE,
    status
})

export const updateEditingType = (editingType) => ({
    type: ActionType.UPDATE_EDITING_TYPE,
    editingType
})

export const updateScale = (scale) => ({
    type: ActionType.UPDATE_SCALE,
    scale
})

export const updateSearchedNode = (node) => ({
    type: ActionType.UPDATE_SEARCHED_NODE,
    node
})