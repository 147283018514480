export default {
    // Data
    UPLOAD_DATA: 'UPLOAD_DATA',
    //fact
    ADD_FACT: 'ADD_FACT',
    UPDATE_FACT: 'UPDATE_FACT',
    UPDATE_GRAPHSUMMARYFACT: 'UPDATE_GRAPHSUMMARYFACT',
    DELETE_FACT: 'DELETE_FACT',
    DELETE_GRAPHSUMMARYFACT: 'DELETE_GRAPHSUMMARYFACT',
    // Story
    GENERATE_STORY: 'GENERATE_STORY',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',//生成进度
    CHANGE_TITLE: 'CHANGE_TITLE',
    CHANGE_SUMMARY: 'CHANGE_SUMMARY',
    CHANGE_ORDER: 'CHANGE_ORDER',
    //user
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_OPERATION: 'UPDATE_USER_OPERATION',
    UPDATE_CONVERT_TYPE: 'UPDATE_CONVERT_TYPE',
    UPDATE_LOCALE: 'UPDATE_LOCALE',
    UPDATE_EDITABLE: 'UPDATE_EDITABLE',
    UPDATE_EDITING_TYPE: 'UPDATE_EDITING_TYPE',
    UPDATE_SCALE: 'UPDATE_SCALE',
    SET_STORY: 'SET_STORY',
    UPDATE_SEARCHED_NODE: 'UPDATE_SEARCHED_NODE',
    // style
    SET_STYLE: 'SET_STYLE',
    UPDATE_NODE_SIZE: 'UPDATE_NODE_SIZE',
    UPDATE_NODE_OPACITY: 'UPDATE_NODE_OPACITY',
    UPDATE_NODE_LABEL: 'UPDATE_NODE_LABEL',
    UPDATE_LINK_WIDTH: 'UPDATE_LINK_WIDTH',
    UPDATE_LINK_OPACITY: 'UPDATE_LINK_OPACITY',
    UPDATE_STYLE: 'UPDATE_STYLE',
    UPDATE_LAYOUT: 'UPDATE_LAYOUT'
}