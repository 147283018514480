import ActionType from '../action/type';
import _ from 'lodash';

const initialState = {
    fileName: '.json',
    title: '',
    data: undefined,
    schema: [],
    method: 'sig',
    facts: [],
    graphSummaryFacts: [],
    relations: [],
    summary: "",
    partition: undefined,
    maxStoryLength: 6,
    timeLimit: 2000,// for each iteration
    information: 40, // default 50 bits for 6 facts
    resultCoverage: 1,
    chartDiversity: 0,
    aggregationLevel: 0,
    rewardWeight: {
        logicality: 0.33,
        diversity: 0.33,
        integrity: 0.33,
    },
    generateProgress: 0,
};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case ActionType.SET_STORY:
            newState.data = action.story.data;
            newState.facts = action.story.facts;
            newState.graphSummaryFacts = action.story.graphSummaryFacts;
            newState.summary = action.story.summary;
            newState.partition = action.story.partition;
            action.story.fileName && (newState.fileName = action.story.fileName);
            action.story.title && (newState.title = action.story.title);
            action.story.schema && (newState.schema = action.story.schema);
            return newState;
        case ActionType.GENERATE_STORY:
            newState.data = action.data;
            newState.facts = action.facts;
            newState.graphSummaryFacts = action.graphSummaryFacts;
            newState.summary = action.summary;
            newState.partition = action.partition;
            return newState;
        case ActionType.UPDATE_PROGRESS:
            newState.generateProgress = Number(((action.totalLength - action.currentLength) / action.totalLength).toFixed(2) * 100);
            return newState;
        case ActionType.UPLOAD_DATA:
            newState.fileName = action.fileName;
            let title = action.fileName.split('.json')[0];
            newState.title = title[0].toUpperCase() + title.slice(1);
            newState.facts = [];
            newState.relations = [];
            newState.schema = action.schema;
            newState.data = action.data;
            return newState;
        case ActionType.CHANGE_TITLE:
            newState.title = action.title;
            return newState;
        case ActionType.CHANGE_SUMMARY:
            newState.summary = action.summary;
            return newState;
        case ActionType.ADD_FACT:
            {
                const fact = _.cloneDeep(action.newFact);
                const newFacts = newState.facts.slice();
                let index = action.index;
                if(index >= 0) 
                    newFacts.splice(index, 0, fact);
                newState.facts = newFacts;
            }
            return newState;
        case ActionType.UPDATE_FACT:
            {
                const fact = _.cloneDeep(action.newFact);
                const newFacts = newState.facts.slice();
                let index = newFacts.indexOf(action.preFact);
                if(index >= 0) newFacts[index] = fact;
                newState.facts = newFacts;
            }
            return newState;
        case ActionType.UPDATE_GRAPHSUMMARYFACT:
            {
                const fact = _.cloneDeep(action.fact);
                const newFacts = newState.graphSummaryFacts.slice();
                newFacts[action.index] = fact;
                newState.graphSummaryFacts = newFacts;
            }
            return newState;
        case ActionType.DELETE_FACT:
            {
                const newFacts = newState.facts.slice();
                let index = newFacts.indexOf(action.fact);
                if(index >= 0) newFacts.splice(index, 1);
                newState.facts = newFacts;
            }
            return newState;
        case ActionType.DELETE_GRAPHSUMMARYFACT:
            {
                const newFacts = newState.graphSummaryFacts.slice();
                newFacts.splice(action.index, 1);
                newState.graphSummaryFacts = newFacts;
            }
            return newState;
        case ActionType.CHANGE_ORDER:
            {
                const newFacts = newState.facts.slice();
                let facts = newFacts.splice(action.oldPos, action.length);
                newFacts.splice(action.newPos, 0, ...facts); 
                newState.facts = newFacts;
            }
            return newState;
        default:
            break;
    }
    return newState;
}