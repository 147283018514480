const EditingType = {
    NEW_STORY: 'NEW_STORY',
    CHANGE_EDITABLE: 'CHANGE_EDITABLE',
    DELETE_FACT: 'DELETA_FACT',
    UPDATE_FACT: 'UPDATE_FACT',
    ADD_FACT: 'ADD_FACT',
    CHANGE_ORDER: 'CHANGE_ORDER',
    SEARCH_NODE: 'SEARCH_NODE',
    UPDATE_STYLE: 'UPDATE_STYLE', //nodesize,nodeopacity,nodelabel,linkopacity,linkwidth
    UPDATE_NODE_COLOR: 'UPDATE_NODE_COLOR',
    NONE: 'NONE',
    DOWNLOADING: 'DOWNLOADING',
    REEDIT: 'REEDIT'
}

export default EditingType;