import ActionType from '../action/type';
import _ from 'lodash';
import Layout from '@/constant/Layout';
import Style from '@/constant/Style';

const initialState = {
    style: Style.DEFAULT,
    layout: Layout.VERTICAL,
    nodeSize: 5,
    nodeOpacity: 1,
    nodeLabel: false,
    linkWidth: 1,
    linkOpacity: 0.18
};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case ActionType.SET_STYLE:
            newState.style = action.style.style;
            newState.layout = action.style.layout;
            newState.nodeSize = action.style.nodeSize;
            newState.nodeOpacity = action.style.nodeOpacity;
            newState.nodeLabel = action.style.nodeLabel;
            newState.linkWidth = action.style.linkWidth;
            newState.linkOpacity = action.style.linkOpacity;
            return newState;
        case ActionType.UPDATE_STYLE:
            if (action.style !== state.style) {
                newState.style = action.style;
                return newState;
            }
        case ActionType.UPDATE_LAYOUT:
            if (action.layout !== state.layout) {
                newState.layout = action.layout;
                return newState;
            }
        case ActionType.UPDATE_NODE_SIZE:
            newState.nodeSize = action.size;
            return newState;
        case ActionType.UPDATE_NODE_OPACITY:
            newState.nodeOpacity = action.opacity;
            return newState;
        case ActionType.UPDATE_NODE_LABEL:
            newState.nodeLabel = action.label;
            return newState;
        case ActionType.UPDATE_LINK_WIDTH:
            newState.linkWidth = action.width;
            return newState;
        case ActionType.UPDATE_LINK_OPACITY:
            newState.linkOpacity = action.opacity;
            return newState;
        default:
            break;
    }
    return newState;
}